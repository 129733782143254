import { NavLayoutUser } from '@shared/common/components/NavLayout/types';

import { Document } from '@ui/uikit/components/icons/mono/Document';

import { UserMessageWrapper } from './UserMessageWrapper';

type HelpButtonsProps = {
  helpButtons?: string[];
  user?: NavLayoutUser;
  onButtonClick: (message: string, messageIndex: number) => void;
  messageIndex: number;
};

export const HelpButtons = ({
  helpButtons = [],
  user,
  onButtonClick,
  messageIndex,
}: HelpButtonsProps) => {
  if (!helpButtons.length) {
    return null;
  }

  return (
    <UserMessageWrapper user={user}>
      <div className="flex flex-wrap gap-2 justify-end">
        {helpButtons?.map((help) => (
          <button
            key={help}
            className="msq-btn msq-btn-lg msq-btn-outline text-caption-sm-a"
            onClick={() => onButtonClick(help, messageIndex)}
          >
            <div className="flex gap-2 items-center">
              <Document className="icon-xs text-base-text-quaternary" />
              {help}
            </div>
          </button>
        ))}
      </div>
    </UserMessageWrapper>
  );
};
