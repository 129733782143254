import Markdown from 'markdown-to-jsx';

import { Message } from '@shared/api/aiAssistant';

import { Typography } from '@ui/uikit/components/common/Typography';
import { MagicAi } from '@ui/uikit/components/icons/poly/MagicAi';

import { ContextButtons } from './ContextButtons/ContextButtons';
import { OverridesConfig } from './MarkdownOverrides/OverridesConfig';

type AiMessageProps = {
  message: Message;
};

// Function to clean the Markdown from unwanted HTML tags and replace \n\n with \n
const cleanMarkdown = (markdown: string): string => {
  // Replace all \n\n with \n, but preserve list structure
  // This is necessary because the library converts \n\n into <p> tags, which breaks list layout.
  // By replacing \n\n with \n, we ensure that the lists are properly rendered without unwanted <p> tags.
  let cleanedMarkdown = markdown.replace(/(\n{2,})(?=\d+\.|\*)/g, '\n');

  // Remove all tags except for certain structural ones, like <a>, <strong>, <em>, <h1> - <h6>, <ul>, <ol>, <li>
  // We do this to avoid rendering unwanted HTML tags that could affect the layout or styling of the content.
  cleanedMarkdown = cleanedMarkdown.replace(
    /<(?!\/?a|\/?strong|\/?em|\/?h[1-6]|\/?p|\/?ul|\/?ol|\/?li)[^>]+>/g,
    '',
  );

  return cleanedMarkdown;
};

export const AiMessage = ({ message }: AiMessageProps) => {
  const cleanContent = cleanMarkdown(message.content);

  return (
    <div className="flex justify-start items-start gap-2 w-full py-[10px]">
      <MagicAi className="w-[24px] h-[24px] flex-shrink-0 mt-2" />
      <div className="flex flex-col gap-2">
        <Typography variant="caption-s">
          <Markdown
            options={{
              wrapper: 'article',
              overrides: OverridesConfig,
            }}
          >
            {cleanContent}
          </Markdown>
        </Typography>
        {!!message?.id && <ContextButtons message={message} />}
      </div>
    </div>
  );
};
