import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

import {
  useAiAssistantGetDialogByIdQuery,
  useAiAssistantStatusQuery,
  useClearAiAssistantCurrentDialogMutation,
} from '@query';

import { MagicAiGetDialogResponse, MagicAiStatusResponse, Message } from '@api';

import { useNavLayout } from '@shared/common/components/NavLayout';
import { useSocket } from '@shared/common/providers/socket';
import { GENERAL_URLS } from '@shared/constants/urls';

import { FormInput } from '@ui/uikit/components/common/FormInput';
import { ArrowRight } from '@ui/uikit/components/icons/mono';
import { MagicAi } from '@ui/uikit/components/icons/poly/MagicAi';

import { useRelatedEntity } from '../../../../lib/aiAgent';
import { WelcomeBlock } from '../../components/WelcomeBlock/WelcomeBlock';
import { AiMessage } from './AiMessage/AiMessage';
import { MarkdownOverrideLink } from './AiMessage/MarkdownOverrides/MarkdownOverrideLink';
import { HelpButtons } from './HelpButtons';
import { UserMessage } from './UserMessage';
import { UserMessageWrapper } from './UserMessageWrapper';

const defaultValues = {
  message: '',
};

interface FormData {
  message: string;
}

const DEFAULT_CATEGORY = 'Main menu';

export const ChatScreen = () => {
  const {
    handleSubmit,
    control,
    reset: resetForm,
  } = useForm<FormData>({
    defaultValues,
    mode: 'onSubmit',
  });

  const { user } = useNavLayout();

  const [category, setCategory] = useState(DEFAULT_CATEGORY);

  const scrollRef = useRef<HTMLDivElement>(null);

  const { socket } = useSocket();

  const { mutateAsync: clearDialog, isPending: isClearDialogPending } =
    useClearAiAssistantCurrentDialogMutation();

  const [data, setData] = useState<MagicAiStatusResponse | MagicAiGetDialogResponse>();

  useEffect(() => {
    const isFirstOpen = !localStorage.getItem('currentSessionChatId');

    if (isFirstOpen) {
      clearDialog().then((data) => {
        localStorage.setItem('currentSessionChatId', data.info.id);
        setData(data);
      });
    }
  }, []);

  const { data: statusData, isPending, isLoading } = useAiAssistantStatusQuery({ enabled: !!user });

  const [chatId, setChatId] = useState('');

  useEffect(() => {
    const storedChatId = localStorage.getItem('currentSessionChatId') || '';
    setChatId(storedChatId);
  }, []);

  const { data: dialogData, isLoading: dialogDataIsLoading } = useAiAssistantGetDialogByIdQuery({
    enabled: !!user && !!chatId,
    dialogId: chatId,
  });

  useEffect(() => {
    if (dialogData?.messages) {
      setData(dialogData);
    } else {
      setData(statusData);
    }
  }, [dialogData]);

  const [messages, setMessages] = useState<Message[]>();
  const isWelcomeBlock = useMemo(
    () => messages?.length === 1 && messages[0].direction === 'incoming',
    [messages],
  );

  const [isSendingMessageDisable, setIsSendingMessageDisable] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);

  useEffect(() => {
    if (!statusData?.isAvailable) {
      return;
    }

    setIsSendingMessageDisable(data?.messages[data?.messages.length - 1]?.type === 'CHOOSE_ONE');
    setCategory(data?.messages[data?.messages.length - 1]?.category || DEFAULT_CATEGORY);
    setMessages([...(data?.messages || [])]);

    socket?.on('assistant-message', ({ message }: { message: Message }) => {
      if (message.category) {
        setCategory(message.category);
      }

      setIsSendingMessageDisable(message.type === 'CHOOSE_ONE');
      setIsSendingMessage(false);
      setMessages((oldData) => [
        ...(oldData || []),
        {
          id: message.id,
          direction: 'incoming',
          type: message.type,
          content: message.content,
          options: message.options || [],
          ratable: message.ratable || false,
          useful: message.useful,
        },
      ]);
    });

    return () => {
      socket?.off('assistant-message');
    };
  }, [statusData, data]);

  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [messages]);

  const { relatedEntity } = useRelatedEntity();

  const userSendMessage = (message: string) => {
    setIsSendingMessageDisable(true);
    setIsSendingMessage(true);
    setMessages((oldData) => [
      ...(oldData || []),
      {
        direction: 'outgoing',
        type: 'TEXT',
        content: message,
      },
    ]);

    socket?.emit('call', 'assistant', {
      message: { content: message },
      context: {
        ...(!!relatedEntity && { relatedEntity }),
      },
    });
    resetForm();
  };

  const clickButton = (message: string, messageIndex: number) => {
    if (messageIndex >= 0) {
      setMessages((oldData) => {
        const newArr = [...(oldData || [])];
        newArr.splice(messageIndex, 1);
        return oldData;
      });
    }

    userSendMessage(message);
  };

  const handleSubmitForm = handleSubmit(async (formData) => {
    userSendMessage(formData.message);
  });

  const renderedMessages = useMemo(() => messages?.slice(2) || [], [messages]);

  if (isClearDialogPending || isPending || isLoading || dialogDataIsLoading) {
    return (
      <div className="flex justify-center items-center h-full">
        <h2 className="text-h2-a">Loading...</h2>
      </div>
    );
  }

  if (!isPending && !statusData?.isAvailable) {
    return (
      <div className="flex justify-center items-center h-full">
        <div className="flex flex-col gap-2 justify-center items-center px-8">
          <p>
            To access the <span className="font-semibold">Mystic AI Agent</span> and unlock the full
            Magic Square experience, you’ll need to boost your{' '}
            <span className="font-semibold">SQRv (Voting Power)</span> by staking{' '}
            <span className="font-semibold">SQR</span> tokens.
          </p>
          <ol className="list-decimal pl-8">
            <li>
              You can stake your tokens{' '}
              <MarkdownOverrideLink href={GENERAL_URLS.staking}>here</MarkdownOverrideLink>.
            </li>
            <li>
              To learn more about SQRv, please see{' '}
              <MarkdownOverrideLink href="https://docs.magicsquare.io/magic-dao/how-to-get-voting-power-in-the-magic-square-dao">
                documentation
              </MarkdownOverrideLink>
              .
            </li>
          </ol>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col justify-between h-full relative">
      {!isWelcomeBlock && (
        <div className="p-3 border-b border-base-border-light">
          <p className="text-caption-sm-a">{category}</p>
        </div>
      )}
      <div className="flex flex-col gap-2 justify-end items-center w-full h-full overflow-hidden">
        <div className="overflow-y-auto h-full px-4 max-md-msq:px-3" ref={scrollRef}>
          {isWelcomeBlock && <WelcomeBlock onCardClick={(message) => clickButton(message, 0)} />}
          {!isWelcomeBlock && (
            <div className="flex flex-col justify-end gap-2 w-full">
              {renderedMessages?.map((message, index) => {
                if (message.type === 'CHOOSE_ONE') {
                  return (
                    <Fragment key={`${message.content}_${index}`}>
                      <AiMessage message={message} />
                      {index === renderedMessages.length - 1 && (
                        <HelpButtons
                          user={user}
                          helpButtons={message?.options}
                          onButtonClick={clickButton}
                          messageIndex={0}
                        />
                      )}
                    </Fragment>
                  );
                }

                if (message.type === 'TEXT') {
                  return (
                    <Fragment key={`${message.content}_${index}`}>
                      {message.direction === 'outgoing' && (
                        <UserMessage message={message.content} user={user} />
                      )}
                      {message.direction === 'incoming' && (
                        <>
                          <AiMessage message={message} />
                          {index === renderedMessages.length - 1 && (
                            <HelpButtons
                              user={user}
                              helpButtons={message?.options}
                              onButtonClick={clickButton}
                              messageIndex={0}
                            />
                          )}
                        </>
                      )}
                    </Fragment>
                  );
                }
              })}

              {category !== DEFAULT_CATEGORY && !isSendingMessage && (
                <HelpButtons
                  user={user}
                  helpButtons={[DEFAULT_CATEGORY]}
                  onButtonClick={clickButton}
                  messageIndex={-1}
                />
              )}
              {isSendingMessage && (
                <div className="flex justify-start items-start gap-2 w-full animate-pulse">
                  <MagicAi className="icon-sm" />
                  <span className="text-base-text-tertiary text-caption-sm-a">
                    Mystic is typing...
                  </span>
                </div>
              )}
            </div>
          )}
        </div>
        {!isWelcomeBlock && (
          <div className="w-full px-4 max-md-msq:px-3 pb-6 max-md-msq:pb-4">
            <UserMessageWrapper user={user}>
              <form
                onSubmit={handleSubmitForm}
                className="w-full flex justify-end gap-2 items-start "
              >
                <Controller
                  control={control}
                  name="message"
                  rules={{
                    required: 'This is required.',
                    maxLength: {
                      value: 500,
                      message: 'Please input less than 500 symbol',
                    },
                    minLength: {
                      value: 2,
                      message: 'Please input more than 2 symbol',
                    },
                  }}
                  disabled={isSendingMessageDisable}
                  render={({ field, fieldState }) => {
                    return (
                      <FormInput
                        isError={!!fieldState.error}
                        hint={fieldState?.error?.message || ''}
                        placeholder="Message Mystic"
                        className="w-full"
                        {...field}
                      />
                    );
                  }}
                />

                <button
                  type="submit"
                  className="msq-btn msq-btn-icon-lg msq-btn-secondary"
                  disabled={isSendingMessageDisable}
                >
                  <ArrowRight className="icon-xs" />
                </button>
              </form>
            </UserMessageWrapper>
          </div>
        )}
      </div>
    </div>
  );
};
