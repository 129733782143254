import { Message } from '@api';

import { CopyButton } from '@ui/uikit/components/common/CopyButton';
import { Tooltip } from '@ui/uikit/components/common/Tooltip';

type CopyMessageButtonProps = {
  message: Message;
  className?: string;
};

export const CopyMessageButton = ({ message, className }: CopyMessageButtonProps) => {
  return (
    <Tooltip
      trigger={
        // without this wrapper div CopyButton doesn't work as tooltip's trigger
        <div>
          <CopyButton value={message.content} className={className} />
        </div>
      }
      side="top"
      caption="Copy"
    />
  );
};
