import { useState } from 'react';

import classNames from 'classnames';

import { Message } from '@api';

import { Tooltip } from '@ui/uikit/components/common/Tooltip';
import { ThumbsUp, ThumbsUpOutlined } from '@ui/uikit/components/icons/mono';

import { DislikeMessageModal } from '../modals/DislikeMessageModal';

type DislikeButtonProps = {
  className?: string;
  message: Message;
};

export const DislikeButton = ({ className, message }: DislikeButtonProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const onClickHandler = () => {
    if (!message.id || !message.ratable) {
      return;
    }

    setIsOpen(true);
  };

  return (
    <>
      {isOpen && (
        <DislikeMessageModal
          onOpenChange={setIsOpen}
          open={isOpen}
          message={message}
          closeModal={() => setIsOpen(false)}
        />
      )}
      <Tooltip
        trigger={
          <button onClick={onClickHandler} className={classNames(className, 'rotate-180')}>
            {message.useful === false ? (
              <ThumbsUp className="size-4 text-base-text-red" />
            ) : (
              <ThumbsUpOutlined className="size-4 text-base-text-primary" />
            )}
          </button>
        }
        side="top"
        caption="Not helpful"
      />
    </>
  );
};
