import { useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { z } from 'zod';

import { Message } from '@api';

import { useRateMessage } from '@shared/common/components/AiAgent/api/useRateMessage';
import displayErrorToast from '@shared/helpers/displayErrorToast';

import { zodResolver } from '@hookform/resolvers/zod';
import {
  DialogButton,
  DialogButtonsWrapper,
  DialogCancelButton,
} from '@ui/uikit/components/common/Dialog';
import { FormControl } from '@ui/uikit/components/common/FormControl';
import {
  FormRadioGroup,
  FormRadioItem,
  FormRadioItemType,
} from '@ui/uikit/components/common/FormRadio';
import { FormTextArea } from '@ui/uikit/components/common/FormTextArea';

const PREDEFINED_COMMENTS: FormRadioItemType[] = [
  {
    value: 'Inaccurate',
    label: 'Inaccurate',
  },
  {
    value: 'Out of Date',
    label: 'Out of Date',
  },
  {
    value: 'Not Helpful',
    label: 'Not Helpful',
  },
  {
    value: 'Too Slow',
    label: 'Too Slow',
  },
];

type DislikeMessageFormProps = {
  onSubmit: () => void;
  message: Message;
};

export const DislikeMessageForm = ({ onSubmit, message }: DislikeMessageFormProps) => {
  const { mutate: rate } = useRateMessage();

  const FormSchema = useMemo(() => {
    return z.object({
      predefinedComment: z.string().min(1, 'Please choose one of the options'),
      customComment: z.string().optional(),
    });
  }, []);

  const [defaultValues] = useState(() => {
    return { predefinedComment: '', customComment: '' };
  });

  const form = useForm<z.infer<typeof FormSchema>>({
    resolver: zodResolver(FormSchema),
    defaultValues,
  });

  const { handleSubmit, control, formState, watch, reset } = form;

  const predefinedCommentValue = watch('predefinedComment');

  const submit = handleSubmit(async ({ predefinedComment, customComment }) => {
    if (!message.id) {
      return;
    }

    const comment = predefinedComment + (customComment ? `. ${customComment}` : '');

    try {
      rate(
        { messageId: message.id, useful: false, comment },
        {
          onError: () => {
            toast.error('Unable to submit your feedback. Please try again later.');
          },
        },
      );
      onSubmit();
      reset();
    } catch (error) {
      displayErrorToast({ error });
    }
  });

  const { isDirty, isSubmitting } = formState;
  const isSubmitButtonDisabled = !isDirty || isSubmitting || !predefinedCommentValue;

  return (
    <form onSubmit={submit}>
      <div className="py-6 flex flex-col gap-4">
        <FormControl label="Response was" visuallyRequired>
          <Controller
            name="predefinedComment"
            control={control}
            render={({ field: { onChange, value: selectedValue } }) => (
              <FormRadioGroup onChange={onChange} className="grid grid-cols-2 gap-2">
                {PREDEFINED_COMMENTS.map(({ value, label }) => {
                  return (
                    <FormRadioItem
                      selected={selectedValue === value}
                      variant="button"
                      key={value}
                      value={value}
                      label={label}
                    />
                  );
                })}
              </FormRadioGroup>
            )}
          />
        </FormControl>
        <Controller
          control={control}
          name="customComment"
          render={({ field: { ref, onChange, value, ...field }, fieldState }) => {
            return (
              <FormTextArea
                textareaRef={ref}
                onValueChange={onChange}
                value={value}
                label="Feedback message (optional)"
                placeholder="Feedback message"
                hint={fieldState.error?.message}
                rows={3}
                maxLength={180}
                {...field}
              />
            );
          }}
        />
      </div>
      <DialogButtonsWrapper className="flex flex-col mt-6 max-xl-msq:mt-4" fullWidth>
        <DialogButton type="submit" isDisabled={isSubmitButtonDisabled}>
          Submit
        </DialogButton>
        <DialogCancelButton />
      </DialogButtonsWrapper>
    </form>
  );
};
