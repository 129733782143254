import { Message } from '@api';

import { CopyMessageButton } from './CopyMessageButton';
import { DislikeButton } from './DislikeButton';
import { LikeButton } from './LikeButton';

const buttonClassname = 'msq-btn msq-btn-icon-sm msq-btn-secondary h-6';

type Props = {
  message: Message;
};

export const ContextButtons = ({ message }: Props) => {
  const showRateButtons = Boolean(
    message?.id && (message.ratable || typeof message.useful === 'boolean'),
  );

  return (
    showRateButtons && (
      <div className="flex gap-1 text-base-text-tertiary">
        <LikeButton message={message} className={buttonClassname} />
        <DislikeButton message={message} className={buttonClassname} />
        <CopyMessageButton message={message} className={buttonClassname} />
      </div>
    )
  );
};
