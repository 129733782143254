import { ReactNode } from 'react';

import { TrendUp } from '@ui/uikit/components/icons/mono';

export const MarkdownOverrideLink = ({ children, href }: { children: ReactNode; href: string }) => (
  <div className="inline-flex items-center cursor-pointer not-italic">
    <a href={href} className="text-base-text-pink" target="_blank" rel="noopener noreferrer">
      {children}
    </a>
    <TrendUp className="text-base-text-pink w-4 h-4" />
  </div>
);
