import { ReactNode } from 'react';

import { Typography } from '@ui/uikit/components/common/Typography';

export const MarkdownOverrideHeading = ({ children }: { children: ReactNode }) => {
  return (
    <div className="mb-2">
      <Typography variant="h5">{children}</Typography>
    </div>
  );
};
