import { MarkdownToJSX } from 'markdown-to-jsx';

import { MarkdownOverrideHeading } from './MarkdownOverrideHeading';
import { MarkdownOverrideLink } from './MarkdownOverrideLink';
import { MarkdownOverrideList, MarkdownOverrideListItem } from './MarkdownOverrideList';
import { MarkdownOverrideParagraph } from './MarkdownOverrideParagraph';

export const OverridesConfig: MarkdownToJSX.Options['overrides'] = {
  a: MarkdownOverrideLink,
  ul: (props) => <MarkdownOverrideList {...props} isOrdered={false} />,
  ol: (props) => <MarkdownOverrideList {...props} isOrdered={true} />,
  li: MarkdownOverrideListItem,
  p: MarkdownOverrideParagraph,
  h1: MarkdownOverrideHeading,
  h2: MarkdownOverrideHeading,
  h3: MarkdownOverrideHeading,
  h4: MarkdownOverrideHeading,
  h5: MarkdownOverrideHeading,
};
