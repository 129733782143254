import { ReactNode } from 'react';

import classNames from 'classnames';

type CustomListProps = {
  children: ReactNode[];
  isOrdered?: boolean;
};

export const MarkdownOverrideListItem = ({ children }: { children: ReactNode }) => {
  return <li className="py-2 ml-4">{children}</li>;
};

export const MarkdownOverrideList = ({ children, isOrdered = false }: CustomListProps) => {
  const ListTag = isOrdered ? 'ol' : 'ul';

  return (
    <ListTag className={classNames('list-inside ', isOrdered ? 'list-decimal' : 'list-disc')}>
      {children}
    </ListTag>
  );
};
