import { ComponentType, SVGProps } from 'react';

import classNames from 'classnames';

import { useNavLayout } from '@shared/common/components/NavLayout';

import * as Dialog from '@radix-ui/react-dialog';
import { CrossLg, Pen, WalletOutlined } from '@ui/uikit/components/icons/mono';

import { AiAgentScreens } from '../../ChatDialog';

type AiAgentMenuItem = {
  Icon: ComponentType<SVGProps<SVGSVGElement>>;
} & (
  | {
      screen?: AiAgentScreens;
      href?: never;
    }
  | {
      screen?: never;
      href?: string;
    }
);

const menuItems: AiAgentMenuItem[] = [
  {
    screen: 'chat',
    Icon: Pen,
  },
  {
    screen: 'balance',
    Icon: WalletOutlined,
  },
];

type MenuProps = {
  onGoToScreen: (screen: AiAgentScreens) => void;
};

export const Menu = ({ onGoToScreen }: MenuProps) => {
  const { user } = useNavLayout();

  const disabled = !user;

  return (
    <div className="flex flex-col p-2 gap-3">
      <Dialog.Close asChild>
        <button className="msq-btn msq-btn-icon-md">
          <CrossLg className="msq-btn msq-btn-icon-child" />
        </button>
      </Dialog.Close>

      <div className="flex flex-col gap-2">
        {menuItems.map(({ Icon, screen }, index) => {
          return (
            <div
              key={index}
              className={classNames(
                'msq-btn msq-btn-secondary msq-btn-icon-md bg-base-bg-tertiary flex-shrink-0',
                { 'msq-btn-secondary-disabled': disabled },
              )}
              onClick={screen ? () => onGoToScreen(screen) : undefined}
            >
              <Icon className={classNames('icon-xs')} />
            </div>
          );
        })}
      </div>
    </div>
  );
};
