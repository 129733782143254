import { ReactNode } from 'react';

import ImageProxy from '@next-image-proxy';

import { NavLayoutUser } from '@shared/common/components/NavLayout/types';

type UserMessageWrapperProps = {
  user?: NavLayoutUser;
  children?: ReactNode;
};

export const UserMessageWrapper = ({ user, children }: UserMessageWrapperProps) => {
  const srcUserAvatar = user?.data.attributes.icon;

  return (
    <div className="flex justify-end items-start gap-2 w-full">
      {children}
      <div className="rounded-full overflow-hidden w-6 h-6 mt-2 flex-shrink-0">
        <ImageProxy
          alt="Users's avatar"
          src={srcUserAvatar}
          width={24}
          height={24}
          className="avatar-img"
        />
      </div>
    </div>
  );
};
