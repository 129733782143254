import { NavLayoutUser } from '@shared/common/components/NavLayout/types';

import { UserMessageWrapper } from './UserMessageWrapper';

export const UserMessage = ({ message, user }: { message: string; user?: NavLayoutUser }) => {
  return (
    <UserMessageWrapper user={user}>
      <span
        className="text-caption-sm-a block px-3 py-[10px] bg-base-bg-secondary rounded-xl"
        dangerouslySetInnerHTML={{ __html: message }}
      ></span>
    </UserMessageWrapper>
  );
};
